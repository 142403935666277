<template>
  <div>
    <v-container>
      <s-crud
        :filter="filter"
        :config="config"
        add
        edit
        remove
        @save="save($event)"
        title="Gastos"
      >
        <template v-slot:filter>
          <v-col>
            <v-row justify="center" style="padding-bottom: 20px">
              <v-col cols="12" lg="6" sm="6" md="6">
                <sselect-client-sap
                  v-model="filter.EleClientName"
                  label="Cliente">
                </sselect-client-sap>
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template scope="props">
          <v-col style="padding-bottom: 30px">
            <v-row>
              <v-col cols="4" class="s-col-form">
                <sselect-client-sap
                  v-model="props.item.EleClientName"
                  label="Cliente"
                ></sselect-client-sap>
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-text
                  v-model="props.item.EleNameExpense"
                  label="Nombre de gastos"
                ></s-text>
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-text
                  decimal
                  v-model="props.item.EleAmountExpense"
                  label="Monto de gasto"
                ></s-text>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </s-crud>
    </v-container>
  </div>
</template>
<script>
import _sLiquidationExpense from "@/services/ExternalTrade/ExtLiquidationExpenseService";
import SselectClientSap from "../../../components/Sale/SselectClientSap.vue";

export default {
  components: {SselectClientSap},
  data : () =>({
    filter : {
      EleClientName:"",
    }, 
    config : {
      model:{
        EleID:"ID"
      },
      service: _sLiquidationExpense,
      headers: [
        { text: "Cliente", value: "EleClientName", width: 90 },
        { text: "Nombre de gasto", value: "EleNameExpense", width: 90 },
        { text: "Monto de gasto", value: "EleAmountExpense", width: 90 },
        
      ],
    },
  }),
  methods : {
    save (item) {
      console.log(item)
      item.UsrCreateID = this.$fun.getUserID();
      item.SecStatus = 1;
      item.save();
    }
  },
}
</script>